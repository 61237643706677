@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: black;
  overflow-x: hidden;
}

/* 
#################################
  Glow Button Border Animation
#################################
*/

@layer utilities {
  /* Act as a border */
  .button-card-wrapper {
    transition: box-shadow 0.3s ease-in-out,
                transform 0.4s ease-in-out; 

    @apply relative bg-black border border-[#00a9d34e] rounded-full overflow-hidden
  }

  /* Gradient */
  .button-card-wrapper::before {
    background: conic-gradient(
      rgba(0, 169, 211, 1) 0deg,
      rgba(151, 234, 255, 1) 0deg,
      transparent 160deg
    );

    @apply absolute h-[80%] w-[150%] left-[-25%] top-[10%] content-[''] animate-button-border-spin;
  }

  /* Wrapper Hover */
  .button-card-wrapper:hover {
    box-shadow: 0 0 100px 70px rgba(0, 169, 211, 0.75);
    transform: scale(1.1);

    @apply bg-[#00A9D3]
  }

  /* Body */
  .button-card-content {
    @apply absolute w-[calc(100%-2px)] h-[calc(100%-2px)] top-[1px] left-[1px] bg-[#101010] rounded-full;
  }

  /* Body Hover */
  .button-card-content:hover {
    @apply bg-[#00A9D3]
  }
}

/*
################################################################## 
  Blue Gradient Used for "Tailgating" + "Facial Recognition" Text 
##################################################################
*/
.text-blue-gradient {
  background: linear-gradient(
    to right,
    #00596F 0%,
    #00A9D3 50%,
    #00596F 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

/*
##############################
  Mobile Navbar Animation
##############################
*/

.sidebar {
  -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}